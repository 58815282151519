import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { File as FileTsga } from '@app/core/models/file.model';
import { map, catchError } from 'rxjs/operators';
import { FileType } from '@app/core/models/file-type.model';
import { environment as env } from 'environments/environment';
import { DQMReport } from '@app/core/models/dqm-report.model';

const routes = {
	dqmreports: 'dqmreports/', 
	getDQMReportsExcel: 'dqmreports/getDQMReportsExcel'
};

@Injectable({
    providedIn: 'root'
})
export class DQMService {
    constructor(
		private apiService: ApiService, 
		private http: HttpClient
    ) {	}
    
    getDQMReports(pageNumber: number = 0, pageSize: number = 10, sortDirection: string = 'desc', sortKey: string = 'dateOfUpload', filterType:string = ''): Observable<DQMReport[]> {
		const params = new HttpParams()
			.set('pageNumber', pageNumber.toString())
			.set('pageSize', pageSize.toString())
			.set('sortDirection', sortDirection)
			.set('sortKey', sortKey)
			.set('filterType', filterType);
		return this.apiService.get(routes.dqmreports, params).pipe(map(res => <DQMReport[]> res));
	}

	getDQMDetailReport(id: number): Observable<DQMReport> {
		return this.apiService.get(routes.dqmreports + id).pipe(map(res => <DQMReport> res));
	}

	getDQMReportsExcel(filterType: string) {
		const params = new HttpParams()
			  .set('filterType', filterType);
	
		return this.http.get(env.baseUrl + routes.getDQMReportsExcel, { params: params, responseType: 'blob' });
	  }

}